<template>
  <div class="Login"></div>
</template>
<script>
import Vue from "vue";
import VueSession from "vue-session";
Vue.use(VueSession);

export default {
  methods: {
    login: function () {
      //fetch para el login
      fetch(
        "https://www.ofisdesigncms.actstudio.xyz/api/cockpit/authUser?token=e96ec97538f5b51ad80b44386e6d1c",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user: "adminl",
            password: "admin",
          }),
        }
      )
        .then((user) => user.json())
        .then((user) => console.log(user))
        .catch((error) => console.log(error));
    },
  },
};
</script>